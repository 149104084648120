#wave {
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    background: #ffffff;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {

  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

.rstm-toggle-icon {
  display: inline-block;
}

.rstm-toggle-icon-symbol {
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
}

.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  border-top: 1px solid #ccc;
  text-align: left;
  width: 100%;
}

.rstm-tree-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  background: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  z-index: unset;
  position: relative;
}

.rstm-tree-item--active {
  color: white;
}

.rstm-tree-item--focused {
  box-shadow: 0 0 5px 0 #222;
  z-index: 999;
}

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%;
}





// .site-header{
//   background-color: #f5f5f5;
// }

// body{
//   background-color: rgb(211 218 227 / 20%);
//   color: #161515;
// }

// .site-main-content__buy-sft .block-item.item-swap-rates, .site-main-content__buy-sft .block-item, .site-header .site-header-action button.btn,.site-main-content__home .block-item{
//   color: #161515;
// }

// .site-main-content__buy-sft .item-manage-your-token .block-item__content:after, .site-main-content__buy-sft .manage-your-token-title .sub-title::before{
//   background-color: #161515;
// }

// .site-main-content__navigation .navigation-vertical__nav li a{
//   color: #0f1010;
// }
// .site-main-content__buy-sft .block-item__wrap, .site-header .site-header-action button.btn.btn-connect{
//   background-color: #ffffff;
// }

// .site-main-content__buy-sft .buy-sft .buy-sft-field input.form-control{
//   background-color: #ffffff;
//   border: 1px solid #cdcdcd5e;
//   color: #0c0c0c;
//   padding-left: 15px;
//   padding-right: 80px;
// }

// .site-main-content__navigation, .site-main-content__home .block-item__wrap, .site-main-content__buy-sft .block-item.item-swap-rates, .modal-content{
//   background-color: #ffffff !important;
// }

// .site-main-content__navigation .navigation-vertical__nav li a:hover{
//   color: #adacac;
// }

// .site-main-content__buy-sft .block-item__wrap{
//   border: 1px solid #888a8d00;
// }

// .site-main-content__navigation{
//   border-right: 2px solid rgb(133 133 133 / 47%);
// }

.btn-primary {
  color: #fff;
  background-color: #cb870a;
  border-color: #cb870a;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 7px 11px 0 rgba(0, 0, 0, 0.15), 0 1px 0 1px #cb870a;
  // background-image: linear-gradient(148deg, #947c18 20%, #ad9d1c 80%);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.47px;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease;
  height: 47px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-primary:hover {
  color: #fff;
  background-color: #e8982e;
  border-color: #e5750f38;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #fffeb5;
  border-color: #fffeb5;
}

.btn {
  border-radius: 1.25rem;
}

.site-header .site-header-action button.btn {
  border-radius: 32px;
}

// .demo_class {
//   border: 1px solid #e1e1e1;
//   border-radius: 4px;
//   padding: 8px 15px;
// }
.site-header .site-header-action button.btn.btn-connect,
.main-title,
.block-item__title,
.nav-link__title {
  color: #ebebeb;
}

.site-header .menu-and-brand .menu-toggle {
  margin-right: 5px;
}

.site-main-content__buy-sft .block-item__wrap {
  border: none;
  // background-color: #1a2132;
  // background: #0091d3;
  // background: linear-gradient(245.22deg, #ad9d1c 23%, #937b18 87.17%, #e8e148 130%) !important;
  /* border: 1px solid #888a8d5e; */
  border-radius: 15px;
  padding: 15px 20px;
  box-shadow: -3px 3px 0px 0px #5b4b06, -6px 6px 11px 0 rgba(0, 0, 5, 0.15), 0 1px 0 1px #5b4b06;
  background-color: #fddb2f;
}

.site-main-content__navigation {
  box-shadow: -3px 3px 0px 0px #5b4b06, -6px 6px 11px 0 rgba(0, 0, 5, 0.15), 0 1px 0 1px #5b4b06;
}

.block-item__wrap , .site-main-content__home .block-item__wrap{
  background-color: #fddb2f;
  // background: linear-gradient(245.22deg, #ad9d1c 23%, #937b18 87.17%, #e8e148 130%) !important;
}

.site-header .site-header-action button.btn.btn-connect {
  background-color: #cb870a;
}
.container-fluid {
  p, h3.block-item__title, .text-center h3, .container span, .block-item__footer, h2 {
    color: #222;
  }
}
.site-main-content__home .block-item__wrap {
  color: #222;
}